<template>
  <div class="container">
    <div class="content">
      <h1>Thank You for Payment</h1>
      <p>You have Paid: ${{ totalPayable }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totalPayable: null,
    };
  },
  mounted() {
    this.totalPayable = this.$route.query.total_payable;
  },
};
</script>

<style>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.content {
  text-align: center;
}
</style>
